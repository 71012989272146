import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';
import HeroInner from '../components/sections/HeroInner/HeroInner.component';
import AboutUs2 from '../components/sections/AboutUs2/AboutUs2.component';
import AboutUs from '../components/sections/AboutUs/AboutUs.component';
import WhyAfrica from '../components/sections/WhyAfrica/WhyAfrica.component';
import MainPitch from '../components/sections/MainPitch/MainPitch.component';

const AboutPage = ({ data }) => {
  const { title, image, aboutUs, aboutUs2, whyAfrica, mainPitch } =
    data.markdownRemark.frontmatter;

  return (
    <Layout>
      <HeroInner image={image} text={title} />
      <AboutUs2 data={aboutUs} bgColor />
      <AboutUs data={aboutUs2} />
      <WhyAfrica data={whyAfrica} />
      <MainPitch data={mainPitch} bgColor />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        aboutUs {
          tagline
          heading
          description
          image1 {
            imageAlt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        aboutUs2 {
          tagline
          heading
          description
          blurbs {
            icon
            title
            description
          }
          image1 {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 270
                  height: 600
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
            imageAlt
          }
          image2 {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 270
                  height: 250
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
            imageAlt
          }
          image3 {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 270
                  height: 250
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
            imageAlt
          }
        }
        whyAfrica {
          tagline
          heading
          description
          image1 {
            imageAlt
          }
          subHeadings {
            title
            descriptions {
              text
            }
          }
        }
        mainPitch {
          icon
          title
          description
          linkLabel
          linkType
          linkURL
        }
      }
    }
  }
`;
